.App {
  display: flex;
  align-items:center;
  justify-content: center;
  width: 100%;
}

.formContainer {
  position: relative;
  width: 500px;
  min-height: 50vh;
  padding: 10px 30px 120px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 15px, rgba(0, 0, 0, 0.05) 0px 3px 6px;
}

.logobar {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  padding: 10px 0px 0px;
  margin-bottom: 20px;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.centered {
  display: block;
  text-align: center;
  margin-top: 2rem;
}

.errorDiv {
  display: flex;
  flex-direction: column;
  font-size: 1em;
  color: rgb(152, 39, 39);
  font-family: "Plus Jakarta Sans", sans-serif;
}

.errorDiv span {
  display: block;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

input {
  /* display: block; */
  width: 20rem;
  height: 2rem;
  padding: 0.5rem;
  font-size: 1.1em;
  font-weight: 500;
  /* margin-bottom: 2rem;*/
}

button {
  border: none;
  padding: 1rem;
  width: 21.2rem;
  font-size: 1.2em;
  border-radius: 0.2rem;
  cursor: pointer;
}

button:hover {
  background-color: #c5c5c5;
}

.message {
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  color: rgb(39, 52, 152);
  font-family: "Plus Jakarta Sans", sans-serif;
}

.message span {
  display: block;
  margin-bottom: 1rem;
}

.message input {
  align-self: center;
  text-align: center;
  width: 80%;
}

.message .invoice {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin-top: 1rem;
}

.message .invoice span {
  width: 33%;
  display: block;
}

.invoice .left:hover {
  cursor: pointer;
}

.qrdisplay {
  text-align: center;
  margin-top: 2rem;
}

.wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paybutton {
  width: 21.2rem;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 11p1.2em;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: rgb(239, 240, 250);
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  }

.paybutton:hover {
  background-color: rgb(39, 52, 152);
  box-shadow: 0px 15px 20px rgb(173, 181, 238);
  color: #fff;
  transform: translateY(-7px);
}

.buttonbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.buttonbox div{
  width: 3rem;
  height: 3rem;
  border: .2rem solid rgb(39, 52, 152);
}

.buttonbox div:hover{
  border: .2rem solid rgb(139, 144, 176);
}

/* Mobile view */
@media screen and (max-width: 600px) {
  .App {
    flex-direction: column;
  }
}